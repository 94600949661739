import React from "react"
import type { PageProps } from "gatsby"
import NewsNestedPostPage from "../../sections/OldDesign/news/Nested/NewsNestedPostPage"
import { WPConstants } from "../../sections/OldDesign/news/Constants"

const CommunityContests: React.FC<PageProps> = ({ location }) => {
    return (
        <NewsNestedPostPage
            pathname={location.pathname}
            title="Community Contests"
            categoryIds={[WPConstants.CATEGORIES.COMMUNITY_CONTESTS]}
        />
    )
}

export default CommunityContests
